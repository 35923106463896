import React from "react";
import { useTranslation } from "react-i18next";

export default function CorporateServices(props) {
  const { t } = useTranslation();
  return (
    <section className="team bg-gradient99 pad-tb">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-8">
            <div className="common-heading ptag">
              <span>{t("OurTeamContent.SectionThree.Title")}</span>
              <h2>{t("OurTeamContent.SectionThree.Description")}</h2>
              <p className="mb0"></p>
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-3 col-sm-6">
            <div className="full-image-card hover-scale">
              <div className="image-div">
                <a href="/#">
                  <img
                    src="https://res.cloudinary.com/deafomwc7/image/upload/v1725318200/codespace/images/team/corporate-support/znk7rqjri2f8zapfr5bm.jpg"
                    alt="team"
                    className="img-fluid"
                  />
                </a>
              </div>
              <div className="info-text-block">
                <h4>
                  <a href="/#">Muhammad Hasbi</a>
                </h4>
                <p>{t("OurTeamContent.SectionThree.List.0.Title")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
