import React from "react";

export default function Careers(props) {
  return (
    <section className="team bg-gradient99 pad-tb">
      <div class="container">
        <div class="row upset ovr-bg2 bd-hor">
          <div class="col-lg-6 col-sm-6 mt30 wow fadeInUp" data-wow-delay=".2s">
            <div
              class="s-block up-hor bd-hor-base"
              style={{ textAlign: "left" }}
            >
              <div class="nn-card-set">
                <h3 style={{ color: "#FFC007" }}>Fullstack Engineer</h3>
                <br />
                <h6>Job: Fullstack Engineer</h6>
                <h6>Grade: Middle to Senior</h6>
                <h6>Experience: 3Years+</h6>
                <h6>Employee Type: Fulltime with Contract 1 Year</h6>
                <br />

                <h4>Requirements</h4>
                <ul class="list-style-">
                  <li>Mahir menggunakan Dart(Flutter)</li>
                  <li>Mahir menggunakan PHP (Laravel & WordPress)</li>
                  <li>
                    Pengalaman menggunakan Laravel Filamen lebih disukai/nilai
                    tambah
                  </li>
                  <li>Pernah menggunakan TypeScript (NestJS)</li>
                  <li>Familiar menggunakan ReactJS</li>
                  <li>Pernah menggunakan InertiaJs adalah nilai plus</li>
                  <li>Familiar menggunakan MongoDB, MySQL, dan Redis</li>
                  <li>
                    Familiar dengan tools CI/CD serta Pengalaman menulis Unit
                    Test adalah nilai plus
                  </li>
                </ul>
                <br />

                <h4>Benefits</h4>
                <ul class="list-style-">
                  <li>
                    WFA (Work From Anywhere) - Kerja bebas dari mana aja, lebih
                    fleksibel!
                  </li>
                  <li>Tunjangan Asuransi BPJS Kelas 1</li>
                  <li>THR</li>
                  <li>Cuti Tahunan</li>
                  <li>Bonus Kuartal (Jika revenue company sedang bagus)</li>
                </ul>

                <div style={{ textAlign: "right" }}>
                  <a
                    href="https://bit.ly/codespace-fullstack"
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{ color: "#FFC007", textAlign: "right" }}
                  >
                    Apply <i class="fas fa-chevron-right fa-icon"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
