import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import ScrollToTop from "./libs/ScrollToTop";
import Header from "./view/Header";
import Footer from "./view/Footer";
import GoTopButton from "./view/GoTopButton";
// import { FloatingWhatsApp } from "react-floating-whatsapp";

import Home from "./view/home/Index";
import AboutUs from "./view/aboutUs/Index";
import OurTeam from "./view/ourTeam/Index";
import ContactUs from "./view/contactUs/Index";
import PortoDetail from "./view/portofolio/detail/Index";
import FloatingWhatsApp from "./view/FloatingWhatsapp";
import Career from "./view/career/Index";

import "./libs/i18n";

function App() {
  return (
    <Router>
      <ScrollToTop>
        <div className="page-wrapper">
          <Header />
          <Switch>
            <Route exact path="/" component={Home} />
            <Route exact path="/about-us" component={AboutUs} />
            <Route exact path="/our-team" component={OurTeam} />
            <Route exact path="/contact-us" component={ContactUs} />
            <Route exact path="/career" component={Career} />
            <Route path="/portofolio/:id" component={PortoDetail} />
          </Switch>
          <FloatingWhatsApp
            phoneNumber="6282141866633"
            accountName="Hiegar Ramadani"
            allowEsc
            allowClickAway
            notification
            notificationSound
          />
          <Footer />
          <GoTopButton />
        </div>
      </ScrollToTop>
    </Router>
  );
}

export default App;
