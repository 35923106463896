import React from "react";
import { useTranslation } from "react-i18next";

export default function Footer(props) {
  const { t } = useTranslation();
  return (
    <div>
      {/* <!--Start Footer--> */}
      <footer className="dark-footer dg-bg--1 nshape dark-footer-1">
        {/* partner */}
        <div className="footer-brands">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 h-center common-heading">
                <h4>{t("Partner.Title")}</h4>
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12">
                <div className="clients-logos text-center col-12">
                  <ul className="row text-center clearfix ">
                    <li
                      className="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn margin-center"
                      data-wow-delay=".2s"
                    >
                      <div className="brand-logo">
                        <a
                          href="https://stasion.org/member/startup/SU0016"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://res.cloudinary.com/deafomwc7/image/upload/v1706170259/codespace/images/partner/stasion_ws7shz.png"
                            alt="partners"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </li>
                    <li
                      className="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn margin-center"
                      data-wow-delay=".2s"
                    >
                      <div className="brand-logo">
                        <a
                          href="https://clutch.co/profile/codespace-indonesia"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://res.cloudinary.com/deafomwc7/image/upload/v1710168257/codespace/images/partner/Daco_5351764_qixjvp.png"
                            alt="partners"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </li>
                    <li
                      className="col-lg-2 col-md-3 col-sm-4 col-6 wow fadeIn margin-center"
                      data-wow-delay=".2s"
                    >
                      <div className="brand-logo">
                        <a
                          href="https://www.goodfirms.co/company/codespace-indonesia"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            src="https://res.cloudinary.com/deafomwc7/image/upload/v1709542938/codespace/images/partner/good-firms-badge_mpda0u.webp"
                            alt="partners"
                            className="img-fluid"
                          />
                        </a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* about company */}
        <div className="container">
          <div className="row justify-content-between pb80">
            <div className="col-lg-2 col-sm-6">
              <h5 className="mb30 mt30">{t("Footer.LeftSection.Title")}</h5>
              <ul className="footer-address-list link-hover">
                <li>
                  <a href="about-us">{t("Footer.LeftSection.List.0")}</a>
                </li>
                <li>
                  <a href="contact-us">{t("Footer.LeftSection.List.1")}</a>
                </li>
                <li>
                  <a href="our-team">{t("Footer.LeftSection.List.2")}</a>
                </li>
                <li>
                  <a
                    href="https://blog.codespace.id"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {t("Footer.LeftSection.List.3")}
                  </a>
                </li>
                <li>
                  <a href="career">{t("Footer.LeftSection.List.4")}</a>
                </li>
              </ul>

              <a
                className="navbar-brand mb30 mt30"
                href="https://play.google.com/store/apps/dev?id=8808853319463086956"
              >
                <img
                  src="https://upload.wikimedia.org/wikipedia/commons/7/78/Google_Play_Store_badge_EN.svg"
                  alt="playstore"
                  width="120"
                />
              </a>
            </div>
            <div className="col-lg-3 col-sm-6">
              <h5 className="mb30 mt30"> {t("Footer.CenterSection.Title")}</h5>
              <ul className="footer-address-list ftr-details">
                <li>
                  <span>
                    <i className="fas fa-envelope"></i>
                  </span>
                  <p>
                    {t("Footer.CenterSection.List.0.Title")}{" "}
                    <span>
                      {" "}
                      <a
                        href="mailto:mail@codespace.id"
                        data-umami-event="Send Email From Footer"
                      >
                        mail@codespace.id
                      </a>
                    </span>
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fas fa-handshake"></i>
                  </span>
                  <p>
                    {t("Footer.CenterSection.List.3.Title")}{" "}
                    <span>
                      {" "}
                      <a
                        href="mailto:partnership@codespace.co.id"
                        data-umami-event="Send Email From Footer"
                      >
                        partnership@codespace.co.id
                      </a>
                    </span>
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fas fa-phone-alt"></i>
                  </span>
                  <p>
                    {t("Footer.CenterSection.List.1.Title")}{" "}
                    <span>
                      {" "}
                      <a
                        href="tel:6282141866633"
                        data-umami-event="Send WA From Footer"
                      >
                        +62 821-4186-6633 (Hiegar)
                      </a>
                    </span>
                  </p>
                </li>
                <li>
                  <span>
                    <i className="fas fa-map-marker-alt"></i>
                  </span>
                  <p>
                    {t("Footer.CenterSection.List.2.Title")}{" "}
                    {/* <span>
                      {" "}
                      Jl Angklung H1 no 1, Kel. Tunggulwulung, Kec. Lowokwaru,
                      Kota Malang, 65141
                    </span> */}
                    <span>
                      {" "}
                      Perum BTWI NO. E-1, Tunggulwulung, Lowokwaru, Kota Malang,
                      Jatim
                    </span>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-lg-4 col-sm-6  ftr-brand-pp">
              <a className="navbar-brand mb30 mt30" href="/#">
                {" "}
                <img
                  src="https://res.cloudinary.com/deafomwc7/image/upload/v1664837459/codespace/images/codespace/logolans_color_czak5s.svg"
                  alt="Logo"
                  width="300"
                />
              </a>
              <p>
                <strong>CV Codespace Indonesia</strong>{" "}
                {t("Footer.RightSection.Description")}
              </p>
              <div className="ff-social-icons mt30">
                <a href="https://facebook.com/108572255363022" target="blank">
                  <i className="fab fa-facebook"></i>
                </a>
                <a href="https://twitter.com/codespaceid" target="blank">
                  <i className="fab fa-twitter"></i>
                </a>
                <a
                  href="https://www.linkedin.com/company/codespaceid"
                  target="blank"
                >
                  <i className="fab fa-linkedin"></i>
                </a>
                <a
                  href="https://www.instagram.com/codespace.id/"
                  target="blank"
                >
                  <i className="fab fa-instagram"></i>
                </a>
                <a href="https://github.com/codespace-id" target="blank">
                  <i className="fab fa-github"></i>
                </a>
                <a
                  href="https://www.youtube.com/channel/UC6I5hsJptCKxWpOx7q7WBuA"
                  target="blank"
                >
                  <i className="fab fa-youtube"></i>
                </a>
                <a href="https://blog.codespace.id" target="blank">
                  <i className="fab fa-medium"></i>
                </a>
              </div>
            </div>
          </div>
        </div>

        {/* copyright and year */}
        <div className="container">
          <div className="row end-footer-">
            <div className="col-lg-6 col-sm-12 text-lg-left mt-2">
              <div className="footer-copyrights- ff-social-icons">
                <a href="https://reactjs.org">
                  Built with R<span className="fab fa-react"></span>actJs
                </a>
              </div>
            </div>

            <div className="col-lg-6 col-sm-12 text-lg-right mt-2">
              <div className="footer-copyrights-">
                <p>
                  ©{new Date().getFullYear()} CV Codespace Indonesia{" "}
                  <span role="img" aria-label="Astronaut">
                    🧑‍🚀
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </footer>
      {/* <!--End Footer--> */}
    </div>
  );
}
